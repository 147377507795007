.c-sidebar {
  &__inner {
    border-radius: $border-radius-sm;
    background: $gray-200;
    margin-bottom: $spacer * 2;
    padding: ($spacer * 1.5) ($spacer * 1.5) ($spacer * 0.5);
  }
  .c-widget:first-child {
    h1, h2, h3 {
      margin-top: 0rem;
    }
  }

  h1, h2, h3 {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
}
