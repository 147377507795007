.c-categories {
  list-style: none;
  .cat-item:not(.current-cat) a {
    text-decoration: underline;
  }
  .current-cat a {
    margin: 0;
  }
}

