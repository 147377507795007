/* libre-baskerville-regular - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-regular.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}
/* libre-baskerville-italic - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'),
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-italic.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}
/* libre-baskerville-700 - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'),
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/LibreBaskerville/libre-baskerville-v7-latin-700.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'CentGothWGL';
  src: url('../assets/fonts/CentGothWGL/CentGothWGL/font.woff2') format('woff2'),
  url('../assets/fonts/CentGothWGL/CentGothWGL/font.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'CentGothWGL';
  src: url('../assets/fonts/CentGothWGL/CentGothWGL-Bold/font.woff2') format('woff2'),
  url('../assets/fonts/CentGothWGL/CentGothWGL-Bold/font.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'CentGothWGL';
  src: url('../assets/fonts/CentGothWGL/CentGothWGL-Italic/font.woff2') format('woff2'),
  url('../assets/fonts/CentGothWGL/CentGothWGL-Italic/font.woff') format('woff');
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: 'CentGothWGL';
  src: url('../assets/fonts/CentGothWGL/CentGothWGL-BdIt/font.woff2') format('woff2'),
  url('../assets/fonts/CentGothWGL/CentGothWGL-BdIt/font.woff') format('woff');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Jenna Sue';
  src: url('../assets/fonts/JennaSue/jenna_sue_pro-webfont.woff2') format('woff2'),
  url('../assets/fonts/JennaSue/jenna_sue_pro-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
