.c-location {
  &__bg {
    height: $spacer * 16;
    background-position: center;
    background-size: cover;
    @include media-breakpoint-up(md) {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 60%;
    }
  }

  &__teamphoto {
    height: $spacer * 5;
    width: $spacer * 5;
    @include media-breakpoint-up(lg) {
      height: auto;
      width: auto;
    }
  }
}
