/** Gravity Forms */
.gform_wrapper form {
  margin-bottom: 0;
}

.gform_wrapper ul {
  @extend .list-unstyled;
}

.gform_wrapper li {
  @extend .form-group;
}

.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: $danger;
}

.gform_title {
  @extend .h2;
}

h3.gform_title {
  display: none !important;
}

.gfield_label {
  color: $secondary !important;
}

.gform_wrapper input[type="email"],
.gform_wrapper input[type="date"],
.gform_wrapper input[type="datetime"],
.gform_wrapper input[type="datetime-local"],
.gform_wrapper input[type="month"],
.gform_wrapper input[type="number"],
.gform_wrapper input[type="password"],
.gform_wrapper input[type="search"],
.gform_wrapper input[type="tel"],
.gform_wrapper input[type="text"],
.gform_wrapper input[type="time"],
.gform_wrapper input[type="week"],
.gform_wrapper input[type="url"],
.gform_wrapper select,
.gform_wrapper textarea {
  &:hover {
    border-color: $quaternary;
  }
  @extend .form-control;
}

.gform_wrapper {
  .gfield {
    margin-bottom: $spacer * 1.5;

    &_label {
      margin-bottom: $spacer * 0.25;
      //@extend .bold;
      color: $dark;
    }

    &_checkbox ,
      &_radio {
      padding-top: $spacer * 0.5;
      li {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        margin-bottom: $spacer * 0.5;

        input:first-child {
          margin-right: 12px;
        }

        label {
          margin-bottom: 0;
          font-weight: $font-weight-base;
          color: $black;
        }
      }
    }
  }
}

.gform_wrapper .button {
  @extend .btn;
  @extend .btn-primary;
}

.gform_wrapper .gfield_error {
  .gfield_label {
    @extend .alert-danger;
  }

  input,
  select,
  textarea {
    @extend .alert-danger;
  }
}

.validation_error {
  @extend .alert;
  @extend .alert-danger;
}

.validation_message {
  display: none;
}

.gform_button {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
  display: none !important;
  position: absolute !important;
  left: -9000px;
}



/**
This is a fix for IE because we use Gravity Forms css and the default css overrules the padding, so we overrule that with
custom styling for IE using the Gravity forms IE tracking class
 */
.gf_browser_ie.gform_wrapper .gform_footer input.button {
  padding: 9px 22px !important;
}

.gf_browser_ie.gform_wrapper .gfield_checkbox li input,
.gf_browser_ie.gform_wrapper .gfield_checkbox li input[type=checkbox],
.gf_browser_ie.gform_wrapper .gfield_radio li input[type=radio] {
  margin-top: 5px !important;
}
