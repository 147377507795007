.c-tease-location {
  &__image {
    img {
      @include media-breakpoint-down(sm) {
        height: 60px;
        width: 60px;
      }
    }
  }
}
