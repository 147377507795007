/*
  Project: Hestiazorg
  Author: Thomas
 */

*[class*="btn-outline-"] {
  border-width: 1px;
}

.btn {

  &-icon {
    svg {
      transform: translate(0, 3px);
    }
    &:hover {
      svg {
        animation: shake 1s;
      }
    }
  }

  &-parent {
    margin-left: 0.6rem;
    > a {
      @extend .btn;
      @extend .btn-outline-secondary;
      color: $secondary !important;
      border-width: 1px;
      font-family: $font-family-sans-serif !important;
      font-size: $font-size-base !important;
      @include media-breakpoint-down('sm') {
        width: calc(100% - (1.5rem ));
        margin: $spacer 0.75rem;
      }
      &:hover {
        background: $secondary;
        color: white !important;
      }
    }
  }

  &:hover {
    text-decoration: none;
    & > * {
      text-decoration: none;
      border-bottom: 0;
    }
  }

  @include media-breakpoint-down('xs') {
    &-xs-block {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  &-white {
    color: $secondary;
    &:hover {
      color: $secondary !important;
      background: transparentize($white, 0.2) !important;
      border-color: transparentize($white, 0.2) !important;
    }
  }

  &-primary {
    color: $secondary;
    &:hover {
      color: lighten($secondary, 10%) !important;
      background: lighten($primary, 12%);
      border-color: lighten($primary, 12%);
    }
  }

  &-secondary {
    &:hover {
      color: $white !important;
      background: lighten($secondary, 8%);
      border-color: lighten($secondary, 8%);
    }
  }

  &-white {
    &:hover {
      color: $black;
      background: $gray-200 !important;
      border-color: $gray-200 !important;
    }
  }

  &-outline-primary {
    color: $primary;
    &:hover {
      color: $primary !important;
      background: transparentize($primary, 0.9);
    }
  }

  &-outline-secondary {
    color: $secondary;
    &:hover {
      color: $secondary !important;
      background: transparentize($secondary, 0.9);
    }
  }
}

.btn-wrapper {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down('md') {
    display: inline-flex;
  }

  .btn-micro {
    text-align: center;
    margin-top: $spacer * 0.5;
    font-size: $font-size-sm;
    color: $secondary;
  }
}
