/*
  Project: hestiazorg
  Author: Thomas
 */

.c-secondary-menu {
  padding: 5px 0 3px;
  li {
    margin-bottom: 0;
  }
  .nav-link {
    color: $secondary;
    font-size: $font-size-sm;
    transition: $transition-base;
    font-family: $font-family-sans;

    &:hover {
      color: $gray-dark;
    }

    &:active {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-down(md) {
    .nav-item:not(.btn-parent) .nav-link.lvl-1 {
      padding: 0.5rem 0.5rem;
    }
  }

  // If there are submenu items only first lvl
  &_sub {
    position: relative;
    padding-right: 1.7rem;

    @include media-breakpoint-down(md) {
      padding-right: 1.3rem !important;
    }

    &:after {
      content: '';
      position: absolute;
      border-top: 6px solid $tertiary;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      transform: rotate(0deg);
      transition: transform 0.25s ease-in-out;
      right: 0.9rem;
      top: 0.9rem;

      @include media-breakpoint-down(md) {
        right: 0.7rem;
      }
    }
  }

  &_hover {
    position: relative;
    &:hover ,
    &:focus {
      > .c-secondary-menu_sub:after {
        transform: rotate(180deg);
      }
      > .c-secondary-menu_dropdown {
        visibility: visible;
        padding-top: 5px;
        opacity: 1;
      }
    }
  }

  // The container for submenu relative is body
  &_dropdown {
    transition: $transition-base;
    visibility: hidden;
    opacity: 0;
    width: 200px;
    height: auto;
    padding-top: 0;
    left: 0;
    top: calc(100% + 2px);
    background: $white;
    position: absolute;
    z-index: 101;
    box-shadow: 0 20px 20px rgba(0,0,0,0.1);
    > li {
      display: inline-block;
      width: 100%;
    }
  }

  &_mobile {
    .nav-link {
      font-size: $font-size-base !important;
    }
    .c-mobile-menu_dropdown .nav-link {
      font-size: $font-size-base * 0.9 !important;
    }
    .nav-item {
      margin-bottom: 0;
    }
  }
}
