.u-images {
  &__brochure {
    width: 100%;
    max-width: 321px;
    @include media-breakpoint-down(md) {
      padding: 0 10%;
    }
    @include media-breakpoint-up(md) {
      margin-top: -70px;

    }

    @include media-breakpoint-up(lg) {
      margin-top: -80px;
    }
  }
}
