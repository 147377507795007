/*
  Project: hestiazorg
  Author: Thomas
 */

.defs-only {
  display:block;
  position: absolute;
  height:0;
  width:0;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
}
