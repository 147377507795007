.u-inner-element {
  &__white {
    * {
      color: white;
    }
    &_bold_link {
      a {
        color: white !important;
        font-weight: $font-weight-bold;
      }
    }
    &_underline_link {
      a {
        color: white !important;
        text-decoration: underline;
      }
    }
  }


  &__secondary {
    * {
      color: $secondary;
    }
    &_link {
      a {
        color: $secondary;
      }
    }
    &_bold_link {
      a {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__primary {
    * {
      color: $primary;
    }
    &_link {
      a {
        color: $primary;
      }
    }
  }

  &__tertiary {
    * {
      color: $tertiary;
    }
    &_link {
      a {
        color: $tertiary;
      }
    }
  }

  &__link_underlined {
    a {
      text-decoration: underline;
    }
  }
}
