.c-icon {
  svg {
    fill: currentColor;
    path {
      fill: $primary;
      stroke: $primary;
    }
  }
  &_service {
    height: 32px;
    width: 32px;
    display: block;

    // The only 1 icon that goes wrong changing fill and stroke color
    &-tandarts {
      svg path {
        fill: transparent !important;
      }
    }
  }
  &_sm {
    transform-origin: left center;
    transform: scale(0.75);

  }
  &_lg {
    transform-origin: left center;
    transform: scale(1.15);
  }
  &_secondary {
    svg {
      path {
        fill: $secondary;
        stroke: $secondary;
      }
    }
  }
  &_tertiary {
    svg {
      path {
        fill: $tertiary;
        stroke: $tertiary;
      }
    }
  }

  &_white {
    svg {
      path {
        fill: $white;
        stroke: $white;
      }
    }
  }
}
