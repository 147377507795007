// Gutenberg color options
// -- see editor-color-palette in functions.php
$colors: (
  'primary' 		: $primary,
  'secondary' 	: $secondary,
  'gray-light' 	: $gray-light,
  'gray-dark' 	: $gray-dark,
);

@each $name, $color in $colors {

  .has-#{$name}-color {
    color: $color;
  }

  .has-#{$name}-background-color {
    background-color: $color;
  }
}
