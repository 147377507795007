a {
  transition: $transition-base;
  &:hover {
    span {
      border-bottom: 0;
    }
  }
}

a.offset-anchor {
  display: block;
  position: relative;
  top: -85px;
  visibility: hidden;

  @include media-breakpoint-up(md) {
    top: -160px;
  }
}

footer {
  a:not(.btn) {
    color: $secondary;
    &:hover {
      color: darken($secondary, 10%);
    }
  }
}
