/*
  Project: hestiazorg
  Author: Thomas
 */


.rounded-sm {
  border-radius: $border-radius-sm !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}
