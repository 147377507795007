.c-hero {
  height: 20rem;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 25rem;
  }

  @include media-breakpoint-up(lg) {
    height: 30rem;
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background-color: $secondary;
  }

  &__background {
    background-size: cover;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    @extend .container;

    &__wrapper {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 80%;
      }

      @include media-breakpoint-up(md) {
        width: 60%;
      }
    }
  }
}

