ul.page-numbers {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
  @include list-unstyled();
  @include border-radius();

  li {

    &:first-child {
      .page-link {
        margin-left: 0;
        @include border-left-radius($border-radius);
      }
    }
    &:last-child {
      .page-link {
        @include border-right-radius($border-radius);
      }
    }

    &.active .page-link {
      z-index: 1;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $gray-dark;
    }

    &.disabled .page-link {
      color: $pagination-disabled-color;
      pointer-events: none;
      // Opinionated: remove the "hand" cursor set previously for .page-link
      cursor: auto;
      background-color: $pagination-disabled-bg;
      border-color: $gray-dark;
    }

    span, a {
      position: relative;
      display: block;
      padding: $pagination-padding-y $pagination-padding-x;
      margin-left: -$pagination-border-width;
      line-height: $pagination-line-height;
      color: $pagination-color;
      background-color: $pagination-bg;
      border: $pagination-border-width solid $gray-dark;
      transition: $transition-base;
      border-radius: $border-radius-sm;
      //font-family: $font-family-sans-serif;

      &:hover {
        z-index: 2;
        color: $pagination-hover-color;
        text-decoration: none;
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
      }

      &.current {
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
        //font-family: $font-family-sans-serif;
      }

      &:focus {
        z-index: 2;
        outline: $pagination-focus-outline;
        box-shadow: $pagination-focus-box-shadow;
      }

      // Opinionated: add "hand" cursor to non-disabled .page-link elements
      &:not(:disabled):not(.disabled) {
        cursor: pointer;
      }
    }
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}
