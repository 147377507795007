.c-google-maps {
  width: 100%;
  height: 400px;
  @include media-breakpoint-up(md) {
    height: 550px;
  }

  // Fixes potential theme css conflict.
  img {
    max-width: inherit !important;
  }


  @include media-breakpoint-down(sm) {
    &__thumb {
      height: 60px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 0.5rem;
    }

    &__content {
      h3 {
        font-size: 1rem !important;
      }
      p, a {
        font-size: 0.9rem !important;
      }
    }
  }

}
