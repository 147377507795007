.swiper {

  &-disabled {
    img {
      width: 100%;
      height: auto;
    }
  }

  .swiper-pagination {
    bottom: -45px;
    .swiper-pagination-bullet {
      background-color: $secondary;
      opacity: 0.2;
      width: 8px;
      height: 8px;
      transition: $transition-base;

      &:hover {
        opacity: 0.5;
      }

      &.swiper-pagination-bullet-active {
        background-color: $secondary;
        opacity: 1;
      }

      &::before {
        top: -8px;
        bottom: -8px;
        left: -8px;
        right: -8px;
      }
    }
  }

  .swiper-slide {
    cursor: pointer;
    position: relative;
    min-height: 100px;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  .swiper-lazy {
    opacity: 0;
    transition: $transition-base;

    &.swiper-lazy-loaded {
      opacity: 1;
    }
  }

  .swiper-zoom-container {
    align-items: start;
  }

  .swiper-slide-zoomed .swiper-zoom-container {
    align-items: center;
  }

  .swiper-zoom-icon {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 99;
    width: 29px;
    height: 30px;
    background: url(../assets/images/icon-zoom-in.svg) center;
    background-size: cover;
    cursor: pointer;
  }

  .swiper-slide-zoomed {
    .swiper-zoom-icon {
      background: url(../assets/images/icon-zoom-out.svg) center;
      background-size: cover;
    }
  }

  .swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    animation: swiper-preloader-spin 1s infinite linear;
    box-sizing: border-box;
    border: 4px solid grey;
    border-radius: 50%;
    border-top-color: transparent;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-control-next,
  .swiper-control-prev {
    position: absolute;
    z-index: 2;
    top: 50%;
    width: 30px;
    height: 30px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    text-align: center;
    padding: 0 20px;
    outline: none;
    transition: $transition-base;
    transform: translateY(-50%);

    @include media-breakpoint-up(sm) {
      width: 40px;
      height: 40px;
      opacity: 0;
    }

    &-icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      position: absolute;
      background: url(../assets/images/icon-arrow.svg) no-repeat center center;
      background-color: rgba($white, 0.7);
      background-size: 12px auto;
      transition: $transition-base;
      border-radius: 50%;

      @include media-breakpoint-up(sm) {
        width: 40px;
        height: 40px;
        background-size: 19px auto;
      }
    }
  }

  .swiper-control-next {
    right: 10px;
  }

  .swiper-control-next-icon {
    transform: rotate(270deg);
  }

  .swiper-control-prev {
    left: 10px;
  }

  .swiper-control-prev-icon {
    transform: rotate(90deg);
  }

  &-products {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
    }

    .swiper-thumbs {
      display: none;
      @include media-breakpoint-up(md) {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        padding-right: $spacer;
      }

      .swiper-slide {
        min-height: auto;
        height: 65px !important;
        width: 65px !important;
        border: 2px solid transparent;

        &:hover {
          border: 2px solid $gray-middle;
        }

        &.swiper-slide-thumb-active {
          border: 2px solid $red;
        }
      }
    }

    .swiper-main {
      .swiper-slide {
        height: 100%;
        min-height: auto;
      }
    }
  }
}


.carousel-control-prev {
  left: 0;

  &.carousel-control-outside {

    @include media-breakpoint-only('md') {
      display: flex;
      left: -55px;
    }

    @include media-breakpoint-only('lg') {
      display: flex;
      left: -60px;
    }
  }

}
.carousel-control-next {
  right: 0;

  &.carousel-control-outside {

    @include media-breakpoint-only('md') {
      display: flex;
      right: -55px;
    }

    @include media-breakpoint-only('lg') {
      display: flex;
      right: -60px;
    }
  }

}


@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

.swiper-product-overview .swiper-slide {
  height:222px;
}

